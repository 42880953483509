export const routes = [
    //题目
    {
        path: '/topic',
        name: 'topic',
        meta:{title:'题目'},
        component: () => import('@/views/topic/index.vue'),
    },

    // 新题目
    {
        name: '我创建的题目列表',
        path: "/topic/myTopicList",
        component: () => import('@/views/topic/myTopicList.vue'),
    },

    { name: 'teachingQuestion',meta:{title:'题目'},path: 'topic',redirect: '/topic'
            ,component: {template:"<router-view></router-view>"}
            ,children:[
                { name: 'teachingQuestionList',meta:{title:'题目列表'},path: 'list',component: () => import('@/views/topic/question/list.vue') },
                { name: 'topicQuestionInfo',meta:{title:'题目详情'},path: ':question_id(N\\d+|P\\d+|\\d+)/info/:tab(desc|analysis)?',component: () => import('@/views/topic/question/detail.vue') ,children: [
                        // { name: 'teachingQuestionInfoComment',meta:{title:'题目讨论'},path: 'comment',component: {
                        { name: 'topicQuestionInfoComment',meta:{title:'题目讨论'},path: 'comment',component: {
                                props:['question_id'],
                                template:'<comment type="question" :where="{id:question_id||$attrs.question_id}"></comment>',
                                components: {
                                    comment: () => import('@/views/api/com/comment.vue'),
                                }
                            }
                        },
                        // { name: 'teachingQuestionInfoRecord',meta:{title:'提交记录'},path: 'record',component: () => import('@/views/topic/question/c/record.vue') },
                        { name: 'topicQuestionInfoRecord',meta:{title:'提交记录'},path: 'record',component: () => import('@/views/topic/question/c/record.vue') },
                    ]
                },
                { name: 'teachingQuestionCensus',meta:{title:'题目统计详情'},path: 'census/:question_id(N\\d+|P\\d+|\\d+)?',component: () => import('@/views/topic/question/census.vue') },
                { name: 'topicQuestionUseCases',meta:{title:'测试用例'},path: ':question_id(N\\d+|P\\d+|\\d+)/use_cases',component: () => import('@/views/topic/question/use_cases.vue') },
            ]
    },
    { name: 'topicQuestionCreate',meta:{title:'添加题目',login:true},path: '/topic/question/save', component: {
        template:`<question-save  style="margin-top: 20px;" title="添加题目"></question-save>`,
        components:{
            questionSave:()=> import('@/views/topic/question/save.vue')
            }
        }
    },
    { name: 'topicQuestionEdit',meta:{title:'编辑题目',login:true},path: '/topic/question/:question_id(N\\d+|P\\d+|\\d+)/save', component: {
            props:['question_id'],
            template:`<question-save  style="margin-top: 20px;" :question_id="question_id" title="编辑题目"></question-save>`,
            components:{
                questionSave:()=> import('@/views/topic/question/save.vue')
            }
        }
    },



    {
        path: '/topic/details/:question_id(N\\d+|P\\d+|\\d+)?/:tab(desc|analysis)?',
        name: 'QuestionInfo',
        meta:{title:'题目详情'},
        component: () => import('@/views/topic/topicDetails.vue'),
            children: [
            { name: 'QuestionInfoComment',meta:{title:'题目讨论'},path: 'comment',component: {
                    template:'<comment type="question" :where="{id:question_id||$attrs.question_id}"></comment>',
                    components: {
                        comment: () => import('@/views/api/com/comment.vue'),
                    },
                    props:['question_id']
                }
            },
            { name: 'QuestionInfoRecord',meta:{title:'提交记录'},path: 'record',component: () => import('@/views/topic/question/c/record.vue') },
        ],
    },
    {
        path: '/topic/topicRecord',
        name: 'topicRecord',
        meta:{title:'答题详情'},
        component: () => import('@/views/topic/topicRecord.vue'),
    },
    // 空白页
    {
        path: '/topic/empty',
        name: 'topicEmpty',
        meta:{title:'答题详情'},
        component: () => import('@/views/topic/empty.vue'),
    },
]